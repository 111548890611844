<template>
	<div class="login">
		<Transition name="fade" appear>
			<h3>{{ translate('welcomeBack') }}</h3>
		</Transition>

		<Transition name="fade-slide-up" appear>
			<form @submit.prevent="submit">
				<div
					class="form-input"
					:class="{ error: error.type === 'email' }"
					:data-error="error.type === 'email' ? error.message : ''">
					<label for="email">{{ translate('emailAddress') }}</label>
					<Icon name="email" width="18" />
					<input id="email" v-model="email" type="email" placeholder="john.doe@email.com" />
				</div>

				<div
					class="form-input"
					:class="{ error: error.type === 'password' }"
					:data-error="error.type === 'password' ? error.message : ''">
					<div class="label">
						<label for="password">{{ translate('password') }}</label>
						<button
							id="forgot-password"
							type="button"
							class="link"
							tabindex="-1"
							@click="$router.push('/login/forgot')">
							{{ translate('login.forgot') }}
						</button>
					</div>
					<Icon name="lock" width="18" />
					<input id="password" v-model="password" type="password" placeholder="••••••••" />
				</div>

				<div v-if="error.type === 'other'" class="error-panel">
					<Icon name="alert-triangle" class="red" width="18" />
					<p>{{ error.message }}</p>
				</div>

				<button type="submit" class="icon-right">
					{{ translate('login') }}<Icon name="arrow-forward" class="white" width="22" />
				</button>
				<p class="no-account">
					{{ translate('login.noAccount') }}
					<router-link
						:to="
							$route.query.redirect ? `/register?redirect=${$route.query.redirect}` : '/register'
						"
						>{{ translate('registerAccount') }}</router-link
					>
				</p>
			</form>
		</Transition>
	</div>
</template>

<script>
import { auth } from '@/lib/supabase'
import Icon from '../components/Icon.vue'

export default {
	components: { Icon },
	data() {
		return {
			email: '',
			password: '',
			error: {
				type: '',
				message: '',
			},
		}
	},
	methods: {
		async submit() {
			this.error = {
				type: '',
				message: '',
			}
			if (!this.email || !this.password) {
				if (!this.email)
					this.handleError({ code: 'no-email', message: 'The email address is required.' })
				if (!this.password)
					this.handleError({ code: 'no-password', message: 'The password is required.' })
				return
			}
			const { user, error } = await auth.signIn({ email: this.email, password: this.password })
			if (user) {
				if (this.$route.query.redirect) {
					this.$router.replace(this.$route.query.redirect)
				} else {
					this.$router.replace('/play')
				}
			} else {
				this.handleError(error)
			}
		},
		handleError(error) {
			switch (error.code) {
				case 'auth/email-already-exists':
				case 'auth/invalid-email':
				case 'no-email':
					this.error.type = 'email'
					this.error.message = error.message
					break
				case 'auth/invalid-password':
				case 'no-password':
					this.error.type = 'password'
					this.error.message = error.message
					break
				default:
					this.error.type = 'other'
					this.error.message = error.message
					break
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.login {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	form {
		margin: 50px auto 0;
		max-width: 350px;
		width: 90%;
	}

	.form-input {
		position: relative;

		@include mobile {
			.label {
				display: flex;
			}
		}

		+ .form-input {
			margin-top: 25px;
		}

		&.error {
			border-color: $red;
			text-align: left;

			input {
				border-color: $red;
			}

			&::after {
				bottom: 0;
				color: $red;
				content: attr(data-error);
				font-size: 12px;
				font-weight: 600;
				left: 4px;
				position: absolute;
				transform: translateY(120%);
			}
		}

		input {
			padding-left: 30px;
		}
	}

	.error-panel {
		align-items: flex-start;
		background: rgba($red, 0.05);
		border: 2px solid rgba($red, 0.25);
		border-radius: $border-radius;
		color: $red;
		display: flex;
		font-size: 14px;
		font-weight: 600;
		margin-top: 25px;
		padding: 10px;
		text-align: left;

		.icon i {
			margin-right: 5px;
			top: 2px;
		}

		p {
			position: relative;
			top: 2px;
		}
	}

	button#forgot-password {
		opacity: 0.5;

		&:hover {
			opacity: 0.75;
		}

		&::before {
			content: '\2022';
			font-size: 10px;
			opacity: 0.5;
			margin: 0 5px;
		}
	}

	button[type='submit'] {
		justify-content: center;
		margin-top: 35px;
		width: 100%;
	}

	.no-account {
		margin-top: 10px;
	}
}
</style>
